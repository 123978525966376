@import url('https://fonts.googleapis.com/css?family=Varela+Round');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
@import url('https://rsms.me/inter/inter.css');

@font-face {
    font-family: 'booksalon';
    src:  url('../assets/fonts/booksalon.eot?9gi6gx');
    src:  url('../assets/fonts/booksalon.eot?9gi6gx#iefix') format('embedded-opentype'),
      url('../assets/fonts/booksalon.woff2?9gi6gx') format('woff2'),
      url('../assets/fonts/booksalon.ttf?9gi6gx') format('truetype'),
      url('../assets/fonts/booksalon.woff?9gi6gx') format('woff'),
      url('../assets/fonts/booksalon.svg?9gi6gx#booksalon') format('svg');
    font-weight: normal;
    font-style: normal;
  }

  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'booksalon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .icon-login:before {
    content: "\e941";
  }
  .icon-logout:before {
    content: "\e942";
  }
  .icon-email-2:before {
    content: "\e93f";
  }
  .icon-lock:before {
    content: "\e940";
  }
  .icon-success:before {
    content: "\e93e";
  }
  .icon-hair-xl:before {
    content: "\e93a";
  }
  .icon-hair-m:before {
    content: "\e93b";
  }
  .icon-hair-s:before {
    content: "\e93c";
  }
  .icon-hair-l:before {
    content: "\e93d";
  }
  .icon-card:before {
    content: "\e939";
  }
  .icon-hearth:before {
    content: "\e934";
  }
  .icon-arrow-simple-down:before {
    content: "\e935";
  }
  .icon-arrow-simple-up:before {
    content: "\e936";
  }
  .icon-arrow-simple-right:before {
    content: "\e937";
  }
  .icon-arrow-simple-left:before {
    content: "\e938";
  }
  .icon-scissors-3:before {
    content: "\e933";
  }
  .icon-stamp-1:before {
    content: "\e92f";
  }
  .icon-stamp-2:before {
    content: "\e930";
  }
  .icon-stamp-3:before {
    content: "\e931";
  }
  .icon-stamp-4:before {
    content: "\e932";
  }
  .icon-rating:before {
    content: "\e90e";
  }
  .icon-location-4:before {
    content: "\e912";
  }
  .icon-phone:before {
    content: "\e92b";
  }
  .icon-web:before {
    content: "\e92c";
  }
  .icon-info:before {
    content: "\e929";
  }
  .icon-location-2:before {
    content: "\e92a";
  }
  .icon-location-3:before {
    content: "\e928";
  }
  .icon-list-2:before {
    content: "\e927";
  }
  .icon-checked-light:before {
    content: "\e926";
  }
  .icon-star-border:before {
    content: "\e924";
  }
  .icon-location:before {
    content: "\e925";
  }
  .icon-sc:before {
    content: "\e920";
  }
  .icon-tw:before {
    content: "\e921";
  }
  .icon-ig:before {
    content: "\e922";
  }
  .icon-fb:before {
    content: "\e923";
  }
  .icon-note:before {
    content: "\e91f";
  }
  .icon-simple-arrow-down:before {
    content: "\e91e";
  }
  .icon-arrow-up:before {
    content: "\e91a";
  }
  .icon-arrow-down:before {
    content: "\e91b";
  }
  .icon-arrow-right:before {
    content: "\e92d";
  }
  .icon-arrow-left:before {
    content: "\e92e";
  }
  .icon-product:before {
    content: "\e91c";
  }
  .icon-dryer:before {
    content: "\e91d";
  }
  .icon-calendar-simple:before {
    content: "\e900";
  }
  .icon-plus:before {
    content: "\e901";
  }
  .icon-coffee:before {
    content: "\e902";
  }
  .icon-break:before {
    content: "\e903";
  }
  .icon-document:before {
    content: "\e904";
  }
  .icon-people:before {
    content: "\e905";
  }
  .icon-calendar-staff:before {
    content: "\e906";
  }
  .icon-calendar-time:before {
    content: "\e907";
  }
  .icon-close:before {
    content: "\e908";
  }
  .icon-trash:before {
    content: "\e909";
  }
  .icon-clock-past:before {
    content: "\e90a";
  }
  .icon-schedule-list:before {
    content: "\e90b";
  }
  .icon-clock-upcoming:before {
    content: "\e90c";
  }
  .icon-clock:before {
    content: "\e90d";
  }
  .icon-calendar:before {
    content: "\e90f";
  }
  .icon-discount:before {
    content: "\e910";
  }
  .icon-plus-round:before {
    content: "\e911";
  }
  .icon-bell:before {
    content: "\e913";
  }
  .icon-scissors-2:before {
    content: "\e914";
  }
  .icon-scissors:before {
    content: "\e915";
  }
  .icon-star:before {
    content: "\e916";
  }
  .icon-checked:before {
    content: "\e917";
  }
  .icon-search:before {
    content: "\e918";
  }
  .icon-envelope:before {
    content: "\e919";
  }
