@import '@booksalon/ui-web-legacy/scss/variables.scss';

.UniformsPercentField {

  .percent-input {
    position: relative;
  }

  .label {
    font-size: 13px;
    margin-bottom: 7px;
    color: #a3a3a3;
  }

  input {
    text-align: center;
    border: 1px solid #e9e9e9;
    background: #fff;
    color: #777777;
    font-size: 16px;
  }

  .clear-cash {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
  }

  &.invalid {
    input {
      border: 1px solid $color-red;
    }
  }

  &.disabled {
    input,
    ::before {
      color: #bbb;
    }
  }
}
