@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: 57.5em) {
      @content;
    }
  } @else if $point == desktopnav {
    @media (min-width: 80.313em) {
      @content;
    }
  } @else if $point == tablet {
    @media (min-width: 50em) {
      @content;
    }
  } @else if $point == phablet {
    @media (min-width: 37.5em) {
      @content;
    }
  } @else if $point == mobileonly {
    @media (max-width: 37.5em) {
      @content;
    }
  } @else if $point == mobileup {
    @media (min-width: 37.5em) {
      @content;
    }
  } @else if $point == tabletdown {
    @media (max-width: 57.5em) {
      @content;
    }
  } @else if $point == tabletup {
    @media (min-width: 57.5em) {
      @content;
    }
  } @else if $point == desktopdown {
    @media (max-width: 93.75em) {
      @content;
    }
  } @else if $point == ismobile {
    @media (max-width: 500px) {
      @content;
    }
  } @if $point == mobilelandscape {
    @media (max-height:600px) {
      @content;
    }
  }
}
