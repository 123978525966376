@import '@booksalon/ui-web-legacy/scss/breakpoints.scss';
@import '@booksalon/ui-web-legacy/scss/variables.scss';

.blank-warning {
  > div {
    max-width: 375px;
  }

  @include breakpoint(mobileonly) {
    > div {
      max-width: 100vw;
    }
  }
}

.ConfirmationDialog {
  display: flex;
  flex-flow: column;
  align-items: center;

  &:not(.deprecated) {
    max-width: 600px;
  }

  .icon {
    font-size: 64px;
    color: #ff8800;
    margin-bottom: 20px;
  }

  .button {
    width: calc(50% - 5px);
  }

  .buttons-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    width: 100%;

    .button:nth-child(2) {
      margin-left: 10px;
    }
  }

  &.deprecated {
    text-align: center;
    padding: 30px;
    font-size: 14px;
    font-family: $font-source-sans;

    .buttons {
      display: flex;
      justify-content: center;
      margin: 40px auto 0 auto;
      width: 145px;
      height: 39px;

      button {
        margin: 0 5px;
        padding: 12px;
        border-radius: 3px;
        flex-basis: 100%;
      }

      display: flex;
      white-space: nowrap;
    }
  }
}

.booksalon-dialog.confirmation-dialog {
  > div + div {
    // popup, not backdrop
    border-top: none;
    border-radius: 8px !important;
    @include breakpoint(mobileonly) {
      max-width: 90vw;
      margin: 0 5vw;
    }
  }
}