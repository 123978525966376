@import '@booksalon/ui-web-legacy/scss/variables.scss';

.UniformsPriceField {
  .price-input {
    position: relative;

    input {
      width: 100%;
      height: 42px;
      padding: 6px 16px;
    }
  }

  .label {
    margin-bottom: 7px;
    color: $color-common-gray;
  }

  input {
    text-align: center;
    border: 1px solid #d0d0d0;
    background: #fff;
    color:  $color-common-gray;
    font-size: 16px;
  }

  .clear-cash {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    cursor: pointer;
  }

  &.invalid {
    input {
      border: 1px solid $color-red;
    }
  }

  &.disabled {
    pointer-events: none;
    input,
    ::before {
      background: #f8f8f8;
      color: #bbb;
    }
  }
}
