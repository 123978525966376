$font-source-sans: "Source Sans Pro", sans-serif;
$font-source-sans-light: "Source Sans Pro Light", sans-serif;
$font-varela: "Varela Round", sans-serif;
$font-quicksand: "Quicksand", sans-serif;
$font-barlow: "Barlow Condensed", sans-serif;
$font-lato: "Lato", sans-serif;
$font-montserrat: "Montserrat", sans-serif;

$color-widget-primary: #663882;
$color-widget-primary-background: #fcf1ff;

$color-purple: #5d416e;
$color-purple-dark: #4d305b;
$color-purple-light: #5e406c;
$color-purple-hightlight: #8d7797;
$color-light-purple: #7e5199;
$color-dark-pink: #7e5199;
$color-pink: #a94fc5;
$color-dark-pink: #a544c2;
$color-body: #f7f7f7;
$color-green: #77c035;
$color-red: #c64817;
$color-blue: #4da1dc;
$color-dark-blue: #3a7ca9;
$color-deep-blue: #1c6adb;
$color-black: #3d3d3d;
$color-common-gray: #444;
$color-dark-gray: #5b5b5b;
$color-gray: #727272;
$color-gray-mega-light: #c2c2c2;
$color-gray-ultra-light: #e5e5e5;
$color-light-gray: #a5a5a5;
$color-nav-gray: #6e6878;
$color-main-gray: #727272;
$color-dark-white: #f2f2f2;
$color-gray-button: #909090;
$color-light-white: #f9f9f9;
$color-container-border: #e9e9e9;
$color-light-border: #efefef;
$color-background: #f8f8f8;
$color-warning-red: #e09a92;
$color-disabled: #dddddd;
$color-active: #67abed;
$sidebar-width: 222px;
$topbar-height: 56px;
$topbar-height-mobile-landscape: 30px;
$sidebar-checkout-open-width: 600px;
$tablet-sidebar-checkout-open-width: 480px;
$sidebar-checkout-close-width: 30px;
$intercom-z-index: 2147483001;
