.devtools-enabled [data-test-id] {
    &:hover {
        outline: 2px solid hotpink;
    }
}

.devtools-enabled [data-test-text] {
    &:hover {
        outline: 2px solid aqua;
    }
}