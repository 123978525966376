@import '@booksalon/ui-web-legacy/scss/breakpoints.scss';

@mixin fullHeight {
  height: calc(
    100vh - 32px
  ); /* Fallback for browsers that do not support Custom Properties */
  height: calc(
    var(--vh, 1vh) * 100 - 32px
  ) !important; // see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  margin-top: 16px;
  transform: none !important;

  > div > div {
    height: 100%;
    max-height: inherit !important;
  }
}

.isNew {
  &.booksalon-dialog {
    &.purple-theme {
      > div {
        > div {
          border-top: none;
          border-radius: 10px !important;
          overflow: hidden;
          max-width: 375px;
        }
      }
    }
  }
}

.booksalon-dialog {
  min-width: 300px;

  > div {
    > div {
      @include breakpoint(mobileup) {
        width: 100%;
        // width: fit-content !important;
      }

      @include breakpoint(mobileonly) {
        // @include fullHeight;
        width: calc(100vw - 32px);
        margin: 0;
      }
    }
  }

  &.purple-theme {
    > div {
      > div {
        border-top: 4px solid #bc7eb6;
        border-radius: 0px !important;
      }
    }
  }

  &.popup-theme {
    > div {
      > div {
        border-radius: 5px !important;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16);
        width: initial !important;
      }
    }
  }
}

.fullHeight {
  @include fullHeight;
}

.fullHeightOnMobile {
  @include breakpoint(mobileonly) {
    @include fullHeight;
  }
}

.Dialog__overlay--light {
  background-color: rgba(255, 255, 255, 0.5) !important;
}
