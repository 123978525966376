@import './print.scss';

html, body {
    // height: 100%;
    box-sizing: border-box;
    font-size: 12pt;
    background: #f5f5f5;
}

* {
    margin: 0;
    padding: 0;
    border: none;
}

// see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
*, *:before, *:after {
    box-sizing: inherit;
}

input:focus
{
    outline:0;
}
