.Toast {
    background-color: #75cf3b;
    color: #fff;
    font-weight: bold;
    min-height: 0px;
    max-width: 90%;
    margin: auto;
    border-radius: 5px;
    padding: 15px;
    button {
        color: #fff;
        opacity: 1;
    }

    &.error {
        background-color: rgb(218, 80, 21);
    }
    &.warning {
        background-color: #FE9F43;
    }
}

.Toastify__toast-container--top-center {
    top: 6px;
}
