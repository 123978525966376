@import '@booksalon/ui-web-legacy/scss/variables.scss';

.Button {
  display: flex;
  justify-content: center;
  align-items: center;

  &__icon {
    margin-right: 20px;

    path:first-child {
      fill: #bbb;
    }
  }

  &.disabled {
    cursor: initial !important;
  }

  &.mobile {
    width: 100%;
    border: 1px solid #e6e6e6;
    border-radius: 5px;
    font-family: Varela Round;
    font-size: 11px;
    color: #9a9a9a;
    text-transform: uppercase;
    padding: 20px;
    background: none;
  }

  &.red {
    background: red;
    &:hover {
      background: red;
    }
  }

  .ProBadge {
    margin-left: 5px;
  }

  .link-button {
    background: transparent;
    color: white;
    cursor: pointer;

    &:hover:enabled,
    &:focus:enabled {
      text-decoration: underline;
    }
    &:disabled,
    &.disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }

    .Button__icon {
      margin-right: 6px;

      path:first-child {
        fill: white;
      }
    }

    &__dark {
      color: $color-black;

      .Button__icon {
        svg {
          fill: $color-black;
        }
      }
    }
  }
}
