@import '@booksalon/ui-web-legacy/scss/variables.scss';
@import '@booksalon/ui-web-legacy/scss/breakpoints.scss';

.common {
  &-card-shadow {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  }
  &-align-right {
    text-align: right;
  }
}

.white-round-button {
  transition: all 0.2s;
  border-radius: 16px;
  padding: 8px 15px;
  cursor: pointer;
  border: 1px solid #9a9a9a;
  color: #9a9a9a;
  font-size: 13px;
  &:hover {
    background-color: #9a9a9a;
    color: #fff;
  }
}

.poor-button {
  background: linear-gradient(#f8f6f5, #fbfafa, #f8f6f5);
  padding: 5px;
  border: 1px solid #ddd;
  border-bottom: 1px solid #c9c9c9;
  border-right: 1px solid #c9c9c9;
  font-size: 12px;
  font-family: $font-quicksand;
  cursor: pointer;
  outline: none;
  color: #666;
  border-radius: 1px;

  &.rounded {
    border-radius: 20px;
    padding: 10px;
  }

  &.primary {
    background: linear-gradient(#8cd451, #77c944);
    color: #fff;
    border-color: #55a915;
    font-weight: bold;
    text-shadow: 0px 1px 2px #666;
  }

  &.disabled {
    opacity: 0.7;
    pointer-events: none;
  }
}

.main-button {
  background: #75cf3c;
  border-bottom: 2px solid #548536;
  &:hover:not(.disabled) {
    background: #7cd73d;
  }
  &.inline {
    background: #44dd3b;
  }
}

.dialog-button {
  align-items: center;
  background: white;
  border-radius: 2px;
  border-radius: 4px;
  border: 1px solid #b4b4b4;
  color: #a3a3a3;
  cursor: pointer;
  display: flex;
  font-family: $font-source-sans;
  font-size: 14px;
  font-weight: 700;
  height: 3rem;
  justify-content: center;
  outline: none;
  width: 100%;

  i {
    font-size: 1.6em;
    margin: 0 0.5em;
  }

  &.inline {
    border: 1px solid #eee;
    border: none;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    height: 1.8rem;
    padding: 0px 10px;
  }

  &.disabled {
    background: #cccccc;
    border-color: #cacbca;
    cursor: initial;
    pointer-events: none;
  }

  &:hover {
    background: white;
    border-color: #5699d9;
  }
}

.widget-button {
  align-items: center;
  background: white;
  border-radius: 2px;
  border-radius: 4px;
  border: 1px solid #efefef;
  color: #a3a3a3;
  cursor: pointer;
  display: flex;
  font-family: $font-quicksand;
  font-size: 11px;
  font-weight: 700;
  height: 3rem;
  justify-content: center;
  outline: none;
  text-transform: uppercase;
  width: 100%;

  i {
    font-size: 1.6em;
    margin: 0 0.5em;
  }

  &.inline {
    border: 1px solid #eee;
    border: none;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    height: 1.8rem;
    padding: 0px 10px;
  }

  &.disabled {
    background: #cccccc;
    border-color: #cacbca;
    cursor: initial;
    pointer-events: none;
  }

  &:hover {
    background: white;
    border-color: #5699d9;
  }
}

.main-button,
.secondary-button,
.white-button {
  width: 100%;
  height: 3.6rem;
  border-radius: 2px;
  color: white;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  i {
    font-size: 1.6em;
    margin: 0 0.5em;
  }
  &.inline {
    display: inline-block;
    border: none;
    height: 1.8rem;
    font-weight: bold;
    padding: 0px 10px;
    border: 1px solid #eee;
    font-size: 12px;
  }
  &.disabled {
    background: #cacbca;
    border-color: #cacbca;
    pointer-events: none;
    cursor: initial;
  }
}

.white-button {
  background: #fff;
  color: #797979;
}

.link-button {
  background: transparent;
  color: white;
  cursor: pointer;

  &:hover:enabled,
  &:focus:enabled {
    text-decoration: underline;
  }
  &:disabled,
  &.disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .Button__icon {
    margin-right: 6px;
    color: white;
  }

  &__dark {
    color: $color-black;

    .Button__icon {
      svg {
        fill: $color-black;

        path:first-child {
          fill: none;
        }
      }
    }
  }
}

.secondary-button {
  background: #5aaae4;
  border-bottom: 2px solid #4789b8;
  &:hover {
    background: #62bcfd;
  }
}

::placeholder {
  color: #cacaca;
}

.clickable,
.pointer {
  cursor: pointer;
}

.hide-on-mobile {
  @include breakpoint(mobileonly) {
    display: none;
  }
}

.invisible {
  visibility: hidden;
}

.pre-line {
  white-space: pre-line;
}

.overflow-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.simple-scrollbar {
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb {
    width: 26px;
    border-radius: 13px;
    background-clip: padding-box;
    border: 10px solid transparent;
    color: rgba(0, 0, 0, 0.3);

    transition-property: color;
    transition-duration: 150ms;
  }

  &::-webkit-scrollbar-thumb {
    min-height: 80px;
    box-shadow: inset 0 0 0 10px;
  }

  &:hover {
    &::-webkit-scrollbar-thumb {
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
